<template>
	<div class="main">
		<div class="alert">
			<el-alert :closable="false" v-if="alertShow" :title="$t('recommend.focusSuccess')" type="success" show-icon>
			</el-alert>
		</div>
		<div class="top">
			<div class="left">{{$t("recommend.musician")}}</div>
			<div class="right">{{$t("recommend.find")}}{{numMusician}}{{$t("recommend.musician")}}</div>
		</div>
		<div class="list-wrap" v-loading="loading">
			<div class="list" v-for="(item,index) in list" :key="index">
				<musician-list @openAlert="openAlert" @cancelfollow="cancelfollow" :index="index" :dataObj="item"></musician-list>
			</div>
			<div class="emptyView">
				<el-empty v-if="isEmpty == true" :image-size="100"></el-empty>
			</div>
			<div class="more-wrap" v-if="isEmpty == false">
				<div v-if="(nothing == false) && (numMusician > pageSize)" class="more" @click="moreMusician">
					<Icon name="icon-7key-musicgengduoyinleren" theme-color="iconColorAll" width="16" height="16"></Icon>
					<div class="more-text">{{ $t('moremusician') }}</div>
				</div>
				<div class="nothing-text" v-if="(nothing == true) || ((numMusician < pageSize) && (numMusician > 0))">{{$t('nomore')}}</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import {
		setTheme
	} from "@/assets/theme";
	import MusicianList from "@/components/content/MusicianList"
	import {
		getCreatorList
	} from "@/api/Home";
	import {
		getfollowing,
		getFollowed
	} from "@/api/Mine";
	export default {
		props: {},
		data() {
			return {
				numMusician: '', //寻找音乐人数量
				page: 1,
				pageSize: 5,
				loading: true,
				isEmpty: false,
				list: [],
				alertShow: false, //关注成功消息提示
				nothing: false,
			};
		},
		components: {
			MusicianList
		},
		computed: {},
		created() {
			this.getCreatorList(); // 首页推荐制作人列表
			
		},
		mounted() {
			// this.getCreatorList();// 首页推荐制作人列表
		},
		watch: {
			page: {
				handler(newValue, oldValue) {
					
					console.log('page', newValue)
					if(this.page == Math.ceil(this.numMusician / this.pageSize)){
						setTimeout(()=>{
							this.nothing = true
						},1000)
						
					}
					// this.themeName = newValue
					// console.log(this.GlobalCSS[newValue])
					// this.$refs.iconFvg.style.fill = this.GlobalCSS[newValue]
				}
			},
		},
		methods: {
			moreMusician(){
				// if(Math.ceil(this.numMusician / this.pageSize))
				// console.log(this.numMusician / this.pageSize)
				console.log(Math.ceil(this.numMusician / this.pageSize))
				if(this.page < Math.ceil(this.numMusician / this.pageSize)){
					this.page = this.page + 1
					this.getCreatorList()
				}
				
			},
			openAlert(data) {
				console.log(data, 'data')
				this.follow(data.creatorId)
				// this.list[data.index].isFollowed = !data.isFollowed
				// if(this.list[data.index].isFollowed == 1){
				// 	this.alertShow = true
				// 	// 两秒之后关注成功提示自动关闭
				// 	setTimeout(() => {
				// 		this.alertShow = false
				// 	}, 2000)
				// }

			},
			
			//添加关注
			follow(creatorId){
				console.log(creatorId,'creatorId')
				getfollowing({
						creatorId: creatorId
					}).then((res) => {
						console.log(res, '添加关注成功');
						if(res.status == 1){
							this.$message.success(res.msg)
						}else {
							this.$message.error(res.msg)
						}
						
						this.getCreatorList();
						// this.list = res.data.list
						// this.numMusician = res.data.count
						// // console.log(res.data.count,'res.data.list.count')
					})
					.catch((err) => {
						console.log(err, '添加关注失败');
					});
			},
			// 取消关注
			cancelfollow(data){
				console.log(data,'取消关注')
				getFollowed({
						creatorId: data.creatorId
					}).then((res) => {
						console.log(res, '取消关注成功');
						if(res.status == 1){
							this.$message.success(res.msg)
						}else {
							this.$message.error(res.msg)
						}
						
						this.getCreatorList();
						// this.list = res.data.list
						// this.numMusician = res.data.count
						// // console.log(res.data.count,'res.data.list.count')
					})
					.catch((err) => {
						console.log(err, '取消关注失败');
					});
			},
			// 首页推荐制作人列表
			getCreatorList() {
				getCreatorList({
						page: this.page,
						pageSize: this.pageSize
					}).then((res) => {
						this.loading = false
						
						console.log(res, '获取推荐制作人列表成功');
						this.numMusician = res.data.count
						// console.log(Math.ceil(this.numMusician / this.pageSize))
						if(this.page == 1){
							this.list = res.data.list
							
						}else {
							this.list = this.list.concat(res.data.list)
						}
						if(this.list == ''){
							this.isEmpty = true
						}else {
							this.isEmpty = false
						}
						// console.log(res.data.count,'res.data.list.count')
					})
					.catch((err) => {
						console.log(err, '获取推荐制作人列表失败');
					});
			},
			changeLang(lang) {
				this.$i18n.locale = lang;
				localStorage.setItem("lang", lang);
			},
			changTheme(name) {
				setTheme(name);
			},
			//头像
			headshow() {
				this.loginstaute = 1;
				this.headconShow = !this.headconShow;
			},
			//消息
			messageshow() {
				this.conShow = !this.conShow;
			},
			//tabbar切换
			handleClick(tab, event) {
				console.log(tab, event);

			},
			// 音乐人详情
			toDeatil(data) {
				console.log(data, '详情')
				this.$router.push({
					path: '/musicianList/musicianPersonalCenter',
					query: {
						creator_id: data
					}
				})
			},
		},
	};
</script>

<style scoped lang="less">
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
	.main {
		display: flex;
		flex-direction: column;
		align-items: center;

		.alert {
			width: 200px;
			position: absolute;

			/deep/.el-alert--success.is-light {
				background: #E4F7F5;
			}

			/deep/.el-alert__title {
				color: #00BEA5;
			}

			/deep/.el-alert--success.is-light {
				color: #00BEA5;
			}
		}

		.top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			height: 81px;
			align-items: center;

			.left {
				color: @recommendMusicianListTextColor;
				font-size: 24px;
				font-weight: 'Semibold';
			}

			.right {
				color: @recommendMusicianListTitleColor;
				font-size: 16px;
			}
		}
		
		.list-wrap{
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			.emptyView{
				width: 100%;
				display: flex;
				justify-content: center;
			}
			.list {
				width: 100%;
				margin-bottom: 16px;
			}
			.more{
				width: 190px;
				height: 36px;
				border-radius: 6px;
				border: 1px solid @recommendMusicianListTextColor;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.more-text{
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: @recommendMusicianListTextColor;
					line-height: 20px;
					margin-left: 10px;
				}
			}
			.nothing-text{
				color: @recommendMusicianListTextColor;
			}
		}
		
	}
</style>
